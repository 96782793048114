<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>改进建议</h1>
      </el-header>
      <el-main class="scrollable-main">
        <span>对本系统有任何改进建议都可填写到下方。</span>
          <el-form label-width="60px" style="margin-top:80px;">
            <el-form-item label="改进建议">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  placeholder="请输入内容"
                  v-model="form.addvice">
              </el-input>
            </el-form-item>
          </el-form>
          <div style="margin-left:80px;">
            <el-button @click="goToHome" size="large">取消</el-button>
            <el-button type="primary" @click="save" size="large" style="margin-left:30px;">确 定</el-button>
          </div>
      </el-main>
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "MAddvice",
  data() {
    return {
      form: {},
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    }
  },
  methods: {
    save() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');

      console.log(formattedRecordTime); // 输出格式为 "YYYY-MM-DD HH:MM:SS"

      const formData = {
        userId: this.user.id, // 报修人的 id
        addvice: this.form.addvice,
        writeTime: formattedRecordTime,
      };
      this.request.post("/addvice", formData).then(res => {
        if (res.code === '200') {
          this.$message.success("提交成功")
          this.request.post("/wechat/sendAddvice", formData)
        } else {
          this.$message.error("提交失败")
        }
      })
    },
    goToHome() {
      this.$router.push('/mobile');
    },
  }
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.menu-item {
  font-size: 25px; /* 设置菜单栏项的字体大小 */
  margin-top: 25px;
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}

</style>
